@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 499px) {
    .imageSection {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    
    .imageSectionSub img {
        width: 130px;
        height: 130px;
        border: 2px white solid;
    
    }
    
    .tokenIds {
        display: flex;
        flex-flow: row wrap;
        width: 90%;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    
    .allWrap {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: #152548;
    }
    
    .name {
        color: #bb3438;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
    }
    
    .headers2{
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }
    
    .headers{
        background-color: black;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        width: 100%;
    }
    
    .wallet2{
        background-color: #bb3438;
        color: white;
        font-weight: bold;
        padding: 7px;
        width: 200px;
        border: none;
        transition: 0.3s ease-in;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 10px;
     }
    
    .wallet2:hover{
        color: #bb3438;
        background-color: white;
    } 
    
     .mintBtn{
        background-color: #bb3438;
        width: 130px;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 3px;
        border: none;
        transition: 0.3s ease-in;
     }
    
     .mintBtn:hover{
        background-color: #ee3b41;
     }
    
     .id{
        color: rgba(255, 255, 255, 0.644);
        text-align: center;
      }
    
     .imageSectionSub{
        margin-bottom: 20px;
        text-align: center;

     }
}


@media screen and (min-width: 500px) and (max-width: 767px) {
    .imageSection {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    
    .imageSectionSub img {
        width: 200px;
        height: 200px;
        border: 2px white solid;
    
    }
    
    .tokenIds {
        display: flex;
        flex-flow: row wrap;
        width: 90%;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    
    .allWrap {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: #152548;
    }
    
    .name {
        color: #bb3438;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
    }
    
    .headers2{
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }
    
    .headers{
        background-color: black;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        width: 100%;
    }
    
    .wallet2{
        background-color: #bb3438;
        color: white;
        font-weight: bold;
        padding: 7px;
        width: 200px;
        border: none;
        transition: 0.3s ease-in;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 10px;
     }
    
    .wallet2:hover{
        color: #bb3438;
        background-color: white;
    } 
    
     .mintBtn{
        background-color: #bb3438;
        width: 200px;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 3px;
        border: none;
        transition: 0.3s ease-in;
     }
    
     .mintBtn:hover{
        background-color: #ee3b41;
     }
    
     .id{
        color: rgba(255, 255, 255, 0.644);
        text-align: center;

      }
    
     .imageSectionSub{
        margin-bottom: 20px;
        text-align: center;

     }
}

@media screen and (min-width: 768px) and (max-width: 1919px) {
.imageSection {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
}

.imageSectionSub img {
    width: 250px;
    height: 250px;
    border: 2px white solid;

}

.tokenIds {
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

    
.allWrap {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background-color: #152548;
}

.banner{
    background: url(/static/media/img.4cc9cb59.jfif) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.intro{
    font-size: 80px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: #bb3438;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.name {
    color: #bb3438;
    font-weight: bold;
    font-size: 25px;
}

.headers2{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.headers{
    background-color: black;
    margin-bottom: 30px;
}

.wallet2{
    background-color: #bb3438;
    color: white;
    font-weight: bold;
    padding: 10px;
    width: 200px;
    border: none;
    transition: 0.3s ease-in;
 }

.wallet2:hover{
    color: #bb3438;
    background-color: white;
} 

 .mintBtn{
    background-color: #bb3438;
    width: 250px;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px;
    border: none;
    transition: 0.3s ease-in;
 }

 .mintBtn:hover{
    background-color: #ee3b41;
 }

 .id{
    color: rgba(255, 255, 255, 0.644);
    font-size: 18px;
    text-align: center;

 }

 .imageSectionSub{
    margin-bottom: 20px;
    text-align: center;

 }
}

@media screen and (min-width: 1920px) {
    .imageSection {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    
    .imageSectionSub img {
        width: 310px;
        height: 310px;
        border: 2px white solid;
    
    }
    
    .tokenIds {
        display: flex;
        flex-flow: row wrap;
        width: 90%;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    
    .allWrap {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: #152548;
    }
    
    .name {
        color: #bb3438;
        font-weight: bold;
        font-size: 30px;
    }
    
    .headers2{
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .headers{
        background-color: black;
        margin-bottom: 30px;
    }
    
    .wallet2{
        background-color: #bb3438;
        color: white;
        font-weight: bold;
        padding: 10px;
        width: 230px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 23px;
     }
    
    .wallet2:hover{
        color: #bb3438;
        background-color: white;
    } 
    
     .mintBtn{
        background-color: #bb3438;
        width: 310px;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 5px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 20px;
     }
    
     .mintBtn:hover{
        background-color: #ee3b41;
     }
    
     .id{
        color: rgba(255, 255, 255, 0.644);
        font-size: 25px;
        text-align: center;

     }
    
     .imageSectionSub{
        margin-bottom: 20px;
        text-align: center;

     }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
    .imageSection {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    
    .imageSectionSub img {
        width: 310px;
        height: 310px;
        border: 2px white solid;
    
    }
    
    .tokenIds {
        display: flex;
        flex-flow: row wrap;
        width: 90%;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    
    .allWrap {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: #152548;
    }
    
    .name {
        color: #bb3438;
        font-weight: bold;
        font-size: 30px;
    }
    
    .headers2{
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .headers{
        background-color: black;
        margin-bottom: 30px;
    }
    
    .wallet2{
        background-color: #bb3438;
        color: white;
        font-weight: bold;
        padding: 10px;
        width: 230px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 23px;
     }
    
    .wallet2:hover{
        color: #bb3438;
        background-color: white;
    } 
    
     .mintBtn{
        background-color: #bb3438;
        width: 310px;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 5px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 20px;
     }
    
     .mintBtn:hover{
        background-color: #ee3b41;
     }
    
     .id{
        color: rgba(255, 255, 255, 0.644);
        font-size: 25px;
        text-align: center;

     }
    
     .imageSectionSub{
        margin-bottom: 20px;
        text-align: center;

     }
}

@media screen and (min-width: 3840px) {
    .imageSection {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }
    
    .imageSectionSub img {
        width: 600px;
        height: 600px;
        border: 2px white solid;
    
    }
    
    .tokenIds {
        display: flex;
        flex-flow: row wrap;
        width: 90%;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }
    
    .allWrap {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        background-color: #152548;
    }
    
    .name {
        color: #bb3438;
        font-weight: bold;
        font-size: 55px;
    }
    
    .headers2{
        display: flex;
        justify-content: space-between;
        padding-top: 80px;
        padding-bottom: 80px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .headers{
        background-color: black;
        margin-bottom: 30px;
    }
    
    .wallet2{
        background-color: #bb3438;
        color: white;
        font-weight: bold;
        padding: 10px;
        width: 400px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 40px;
     }
    
    .wallet2:hover{
        color: #bb3438;
        background-color: white;
    } 
    
     .mintBtn{
        background-color: #bb3438;
        width: 600px;
        border: none;
        color: white;
        border-radius: 5px;
        padding: 10px;
        border: none;
        transition: 0.3s ease-in;
        font-size: 35px;
     }
    
     .mintBtn:hover{
        background-color: #ee3b41;
     }
    
     .id{
        color: rgba(255, 255, 255, 0.644);
        font-size: 45px;
        text-align: center;

     }
    
     .imageSectionSub{
        margin-bottom: 20px;
        text-align: center;

     }
}
